/**
 * 
 * Hair form retest component
 * Currently handles form submissions that process hair submission retests
 * This queries the backend and has standard form validation
 * 
 * @method submitHandler : () => void
 * @returns HairRetestForm : PureComponent
 */

import React from "react";
import "./HairRetestForm.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink } from "react-router-dom";
import { BsArrowLeftSquareFill } from "react-icons/bs";

// endpoint
// Dev
// const endpoint = "http://localhost:80/activatemytest/api/controllers/RetestController.php";
// Staging
const endpoint = "https://staging.activatemytest.com/api/controllers/RetestController.php";
// Live
// const endpoint = "https://activatemytest.com/api/controllers/RetestController.php";

const HairRetestForm = () => {

    // Input refs
    const reCaptchaRef = React.useRef<ReCAPTCHA>(null);
    const previousOrderRef = React.useRef<HTMLInputElement>(null);
    const newOrderRef = React.useRef<HTMLInputElement>(null);
    const postcodeRef = React.useRef<HTMLInputElement>(null);

    // Use state hooks
    const [isPreviousOrderNumberValid, setIsPreviousOrderNumberValid] = React.useState<boolean>(true);
    const [isNewOrderNumberValid, setIsNewOrderNumberValid] = React.useState<boolean>(true);
    const [previousOrderErrorText, setPreviousOrderErrorText] = React.useState<string>();
    const [newOrderErrorText, setNewOrderErrorText] = React.useState<string>("");
    const [postcodeErrorText, setPostcodeErrorText] = React.useState<string>("");
    const [showPasswordModal, setShowPasswordModal] = React.useState<boolean>(false);
    const [isPostcodeValid, setIsPostcodeValid] = React.useState<boolean>(true);
    const [isRecaptchaValid, setIsRecaptchaValid] = React.useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);
    const [showForm, setShowForm] = React.useState<boolean>(true);

    // Make sure the input is not empty
    const validateInput = (input : string) => {
        return input.length > 0;
    };

    // Check our order nubmers
    const validateOrderNumbers = () => {

        let prevOrderNumberValid, newOrderNumberValid : boolean = true;

        // Check if the inputs are valid
        if (previousOrderRef.current) {
            prevOrderNumberValid = validateInput(previousOrderRef.current.value);
            setIsPreviousOrderNumberValid(prevOrderNumberValid);
            setPreviousOrderErrorText("Error: Previous order number is empty");
        }

        if (newOrderRef.current) {
            newOrderNumberValid = validateInput(newOrderRef.current.value);
            setIsNewOrderNumberValid(newOrderNumberValid);
            setNewOrderErrorText("Error: New order number is empty");
        }

        // If either order number is invalid
        if (!prevOrderNumberValid || !newOrderNumberValid) {
            return false;
        }else{
            return true;
        }
    };

    // Process order number
    const submitOrderNumberHandler = async( event : React.FormEvent ) => {

        // Don't reload the page
        event.preventDefault();

        // Check if order numbers are equal
        const ordersValid = validateOrderNumbers();

        // Stop execution if the numbers are invalid
        if (ordersValid === false) {
            return;
        }

        // If we have a value set in our ref input
        if (previousOrderRef.current && newOrderRef.current && reCaptchaRef.current) {

            let recaptchaToken : string = "";
            const captchaValue = reCaptchaRef.current.getValue();

            // If our recaptcha token is valid
            if( captchaValue !== null ){
                recaptchaToken = captchaValue;
            }

            // Create formdata to send to the backend to be processed
            const fields = new FormData();
            fields.append("previousOrder", previousOrderRef.current.value);
            fields.append("newOrder", newOrderRef.current.value);
            fields.append("recaptcha", recaptchaToken);
            fields.append("queryType", "check_order_numbers");
            fields.append("checkPostcode", "false");

            // Perform API request
            // Note: Do not pass a content type through to this request as the browser completes for you as well as your boundary
            const response = await fetch(endpoint, {
                method: "POST",
                body: fields
            });
    
            // Execute the fetch request
            const data = await response.json();

            console.log("Result of query");
            console.log(data);

            // Check if the new order number is valid
            if (data.newNumberValidLIMS === true) {
                setIsNewOrderNumberValid(true);
            };

            // If the new order number isn't valid
            if (data.newNumberValidLIMS === false) {
                setIsNewOrderNumberValid(false);
                setNewOrderErrorText("Error: Order number not found in LIMS");
            }

            // If the order numbers are valid in LIMS and AMT
            if (( data.prevNumberValidLIMS === true ) && ( data.prevNumberValidAMT === true )) {
                setIsPreviousOrderNumberValid(true);
            }

            // If the order number is only valid in LIMS
            if (( data.prevNumberValidLIMS === false ) && ( data.prevNumberValidAMT === true )) {
                setIsPreviousOrderNumberValid(false);
                setPreviousOrderErrorText("Order number not found in LIMS");
            }

            // If the order number is only valid in AMT
            if (( data.prevNumberValidLIMS === true ) && ( data.prevNumberValidAMT === false )) {
                setIsPreviousOrderNumberValid(false);
                setPreviousOrderErrorText("Order number not found in Activate My Test");   
            }

            // If the order number isn't valid anywhere
            if (( data.prevNumberValidLIMS === false ) && (data.prevNumberValidAMT === false )) {
                setIsPreviousOrderNumberValid(false);
                setPreviousOrderErrorText("Order number is not valid in Activate My Test or LIMS");
            }
            
            // If both numbers are valid, show the postcode field and validate it
            if ( ( data.prevNumberValidLIMS === true ) && ( data.newNumberValidLIMS === true ) && ( data.prevNumberValidAMT === true )) {
                setIsPostcodeValid(true);
                setShowPasswordModal(true);
            }

            // Set the recaptcha as being valid
            if ( isRecaptchaValid !== true ) {
                setIsRecaptchaValid(data.recaptchaValid);
            }

            // Set form submitted to be true for ReCaptcha validation
            setFormSubmitted(true);
        }
    };

    // Process postcode
    const submitPostcodeHandler = async( event : React.FormEvent ) => {

        // Don't reload the page
        event.preventDefault();

        let postcodeIsntEmpty : boolean = false;

        // If the postcode exists
        if ( postcodeRef.current && previousOrderRef.current ) {

            postcodeIsntEmpty = validateInput(postcodeRef.current.value);
            setIsPostcodeValid(postcodeIsntEmpty);

            // If the postcode field is empty
            if ( postcodeIsntEmpty === false ) {

                setPostcodeErrorText("Error: Postcode field is empty");
                return;
            }

            // Query the backend 
            const fields = new FormData();
            fields.append("postcode", postcodeRef.current.value);
            fields.append("previousOrder", previousOrderRef.current.value );
            fields.append("queryType", "check_postcode");

            // Perform API request
            // Note: Do not pass a content type through to this request as the browser completes for you as well as your boundary
            const response = await fetch(endpoint, {
                method: "POST",
                body: fields
            });

            // Execute the fetch request
            const data = await response.json();

            console.log("Postcode submit result");
            console.log(data);

            // If the postcode is valid on both AMT and LIMS
            if ( ( data.postcodeValidAMT === true ) && ( data.postcodeValidLIMS === true ) ) {
                setIsPostcodeValid(true);
                setShowPasswordModal(false);

                // Toggle the form and success modal
                setShowForm((previousState : boolean) => {
                    return !previousState;
                });
            }

            // If the postcode is only valid on AMT
            if ( ( data.postcodeValidAMT === false ) && ( data.postcodeValidLIMS === true ) ) {
                setIsPostcodeValid(false);
                setPostcodeErrorText("Error: Postcode is not valid in Activate My Test");
            } 

            // If the postcode is only valid in LIMS
            if ( ( data.postcodeValidAMT === true ) && ( data.postcodeValidLIMS === false ) ) {
                setIsPostcodeValid(false);
                setPostcodeErrorText("Error: Postcode is not valid in LIMS");
            }

            if ( ( data.postcodeValidAMT === false ) && ( data.postcodeValidLIMS === false ) ) {
                setIsPostcodeValid(false);
                setPostcodeErrorText("Error: postcode doesn't exist in the database");
            }
        }
    };

    // Page refresh handler
    const pageRefreshHandler = (event : React.MouseEvent) => {
        event.preventDefault();

        window.location.reload();
    };

    return(
        <main className="hair-retest">

            <NavLink to="/hair-submissions" className="hair-retest__back-button"><BsArrowLeftSquareFill/>Go Back</NavLink>

            {
                showPasswordModal &&
                <form className="hair-retest__form" onSubmit={submitPostcodeHandler}>

                    <h1 className="hair-retest__title">Verify your postcode</h1>

                    <div className={`hair-retest__field ${ !isPostcodeValid && 'hair-retest__field--error' }`}>

                        <label id="postcode_label" htmlFor="postcode" className="hair-retest__label">
                            { isPostcodeValid ? 'Postcode' : postcodeErrorText }
                        </label>
                        <input
                            type="text"
                            name="postcode"
                            aria-labelledby="postcode_label"
                            placeholder="Please enter the postcode..."
                            aria-required
                            className={`hair-retest__input ${ !isPostcodeValid && 'hair-retest__input--error' }`}
                            ref={postcodeRef}
                        />

                    </div>

                    <button className="hair-retest__button">Verify Postcode</button>
                </form>
            }

            {
                showForm &&                
                <form className="hair-retest__form" onSubmit={submitOrderNumberHandler}>

                    <h1 className="hair-retest__title">Submit a retest</h1>

                    <div className={`hair-retest__field ${ !isPreviousOrderNumberValid && 'hair-retest__field--error' }`}>
                        <label id="previous_order_label" htmlFor="previous_order" className="hair-retest__label">{ isPreviousOrderNumberValid ? 'Previous Order Number' : previousOrderErrorText }</label>
                        <input
                            type="text"
                            name="previous_order"
                            aria-labelledby="previous_order_label"
                            placeholder="Please enter the previous order number..."
                            aria-required
                            className={`hair-retest__input ${ !isPreviousOrderNumberValid && 'hair-retest__input--error' }`}
                            ref={previousOrderRef}
                        />
                    </div>

                    <div className={`hair-retest__field ${ !isNewOrderNumberValid && 'hair-retest__field--error' }`}>
                        <label id="new_order_label" htmlFor="new_order" className="hair-retest__label">{ isNewOrderNumberValid ? 'New Order Number' : newOrderErrorText }</label>
                        <input
                            type="text"
                            name="new_order"
                            aria-labelledby="new_order_label"
                            placeholder="Please enter the new order number..."
                            aria-required
                            className={`hair-retest__input ${ !isNewOrderNumberValid && 'hair-retest__input--error' }`}
                            ref={newOrderRef}
                        />
                    </div>

                    <div className={`hair-retest__field ${ !isRecaptchaValid && 'hair-retest__field--error' }`}>
                        {
                            (!isRecaptchaValid && formSubmitted) &&
                            <label id="recaptcha_label" htmlFor="recaptcha" className="hair-retest__label">Error: Recaptcha is invalid</label>
                        }
                        <ReCAPTCHA
                            sitekey="6Ld_2pskAAAAAPFePhfrk2K7T3mVksszVs9XF16X"
                            ref={reCaptchaRef}
                        />
                    </div>

                    <button className="hair-retest__button">Submit Retest</button>
                </form>
            }

            {
                !showForm &&
                <div className="hair-retest__modal-wrapper">
                    <h2 className="hair-retest__modal-title">Success! Retest submitted</h2>
                    <p className="hair-retest__modal-text">Congratulations, you have successfully submitted your retest</p>
                    <p className="hair-retest__modal-text">If you would like to submit another retest, please click on the button below</p>
                    <button className="hair-retest__modal-button" onClick={pageRefreshHandler}>Reload</button>
                </div>
            }

        </main>
    );
};

export default HairRetestForm;
