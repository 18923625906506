/**
 * Hair Form component.
 * Takes form data and validates it.
 * If successful, an api request is sent to './api/HairSubmission.php'.
 * A modal is shown on a successful submission.
 * The postcode checker checks for regional postcodes using the postcode validator package.
 * 
 * @method validateInput : (string) => boolean
 * @method validateEmail : (string) => boolean
 * @method validateCodeLength : (string) => boolean
 * @method validateRegionalPostcode : (string) => boolean
 * @method queryLIMS : async () => void
 * @method formatResults : (QueryInterface) => void
 * @method submitHandler : (React.FormEvent) => void
 * 
 * @returns HairForm : PureComponent 
 */

 import "./AmazonHairForm.scss";
 import { FormEvent, useRef, useState } from "react";
 import { NavLink, useNavigate } from "react-router-dom";
 import { BsArrowLeftSquareFill } from "react-icons/bs";
 import DatePicker from "react-datepicker";
 import "react-datepicker/dist/react-datepicker.css";
 import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
 import ReCAPTCHA from "react-google-recaptcha";
 import PhoneInput from 'react-phone-input-2';
 import 'react-phone-input-2/lib/style.css';
 
 // endpoint
 // Dev
 // const endpoint = "http://localhost:80/activatemytest/api/controllers/HairSubmission.php";
 // Staging
 const endpoint = "https://staging.activatemytest.com/api/controllers/HairSubmission.php";
 // Live
 //const endpoint = "https://activatemytest.com/api/controllers/HairSubmission.php";
 
 // const hairExportEndpoint = "http://localhost:80/activatemytest/api/cron/HairExport.php";
 
 const HairForm = () => {
 
     // Navigate the route to the thank you page after submission
     const navigate = useNavigate();
 
     if (postcodeValidatorExistsForCountry("GB") === false){
 
         throw new Error("Warning, postcode validator doesn't work. Please check the package");
     }
 
     // Input refs
     const firstNameRef = useRef<HTMLInputElement>(null);
     const lastNameRef = useRef<HTMLInputElement>(null);
     const emailRef = useRef<HTMLInputElement>(null);
     const secondEmailRef = useRef<HTMLInputElement>(null);
     const postcodeRef = useRef<HTMLInputElement>(null);
     const orderNumberRef = useRef<HTMLInputElement>(null);
     const reCaptchaRef = useRef<ReCAPTCHA>(null);
     const genderRef = useRef<HTMLSelectElement>(null);
     const activationCodeRef = useRef<HTMLInputElement>(null);
 
     // Form validation states
     const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
     const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
     const [isPostcodeValid, setIsPostcodeValid] = useState<boolean>(true);
     const [isOrderNumberValid, setIsOrderNumberValid] = useState<boolean>(true);
     const [isSecondEmailValid, setIsSecondEmailValid] = useState<boolean>(true);
     const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
     const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date("2000-01-01"));
     const [orderNumberErrorMsg, setOrderNumberErrorMsg] = useState<string>("");
     const [isActivationCodeValid, setIsActivationCodeValid] = useState<boolean>(true);
     const [formSuccess, setFormSuccess] = useState<boolean>(false);
     const [showPopup, setShowPopup] = useState<boolean>(false);
     const [isReCaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);
     const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
     const [phoneNumber, setPhoneNumber] = useState<string>("");
 
 
     // Validate regional postcode
     const validateRegionalPostcode = (postcode : string) => {
 
         let postcodeIsValid = false;
 
         // Postcodes array, we validate these to make the validator "global"
         const postcodes = [
             "GB",
             "US",
             "AU",
             "CA",
             "DE",
             "NL",
             "PL",
             "FI",
             "FR",
             "DE",
             "CH",
             "CN",
             "SE",
             "IE",
             "LU",
             "NO",
             "BE",
             "AT",
             "PL",
             "SG",
             "KR",
             "NZ",
             "JP",
             "MY",
             "AR",
             "ZA",
             "TW",
             "TH"
         ];
 
         // Loop through the array of postcodes to match the postcode
         // If true, we return true at the end of this function, otherwise, it stays false
         // eslint-disable-next-line array-callback-return
         postcodes.map((code : string) => {
 
             if (postcodeValidator(postcode, code) === true) {
                 postcodeIsValid = true;
             }
         });
 
         return postcodeIsValid;
     };
 
     // Validate our email address as being valid by checking it against regular expression
     const validateEmail = (email : string) => {
         return /^[^@]+@[^@]+\.[^@]+$/.test(email);
     };
 
     // Make sure the input is not empty
     const validateInput = (input : string) => {
         return input.length > 0;
     };
 
     // Make sure the Activation Code is 10 characters long
     const validateActivationCode = (input : string) => {
         return input.length === 10;
     };
 
     const validateOrderNumber = (code : string) => {
         // Regular expression to check if the code contains only numbers
         const validOrderNumberPattern = /^[0-9]+$/;
     
         if (!validOrderNumberPattern.test(code)) {
             setOrderNumberErrorMsg("Error: Order number must contain only numbers.");
             return false;
         }
     
         // If the input passes the check, clear the error message
         setOrderNumberErrorMsg("");
         return true;
     };
     
     // // Make sure that our Order Number is at least 8 characters long
     // const validateOrderNumber = (code : string) => {
 
     //     // Split the string into an array
     //     const split = code.split("-");
 
     //     if (split.length < 2){
 
     //         setOrderNumberErrorMsg("Error: Order Number should contain at least 1 dash");
     //         return false;
 
     //     }
 
     //     if (code.length >= 8){
 
     //         setOrderNumberErrorMsg("");
     //         return true;
 
     //     }else{
 
     //         setOrderNumberErrorMsg("Error: Order Number must be at least 8 characters long");
     //         return false;
 
     //     }
     // };
 
     // Validate our fields on the front end, and then submit them in order to validate them server
     // If validation server side fails, then return an error

     const submitHandler = (event : FormEvent) => {
 
         event.preventDefault();
 
         // We set this to true for our recaptcha
         setFormSubmitted(true);
 
         let orderNumberValid, firstNameValid, lastNameValid, postcodeValid, emailValid, confirmEmailValid, tokenValid, activationCodeValid : boolean = false;

         if (orderNumberRef.current) {
             orderNumberValid = validateOrderNumber( orderNumberRef.current.value );
             setIsOrderNumberValid( orderNumberValid );
         }
 
         //  Validate inputs on the front end
         if (firstNameRef.current) {
             firstNameValid = validateInput( firstNameRef.current.value );
             setIsFirstNameValid( firstNameValid );
         }
 
         if (lastNameRef.current) {
             lastNameValid = validateInput( lastNameRef.current.value );
             setIsLastNameValid( lastNameValid );
         }
 
         if (activationCodeRef.current) {
             activationCodeValid = validateActivationCode( activationCodeRef.current.value );
             setIsActivationCodeValid( activationCodeValid );
         }
 
         if (postcodeRef.current) {
             postcodeValid = validateRegionalPostcode(postcodeRef.current.value)
             setIsPostcodeValid( postcodeValid );
         }
 
         if (emailRef.current) {
             emailValid = validateEmail( emailRef.current.value );
             setIsEmailValid(emailValid);
         }
 
         if (emailRef.current && secondEmailRef.current) {
             confirmEmailValid = (emailRef.current.value === secondEmailRef.current.value);
             setIsSecondEmailValid(confirmEmailValid);
         }
 
         if (reCaptchaRef.current) {
             const token = reCaptchaRef.current.getValue();
 
             // If token isn't null ( which it won't be )
             if (token) {
                 tokenValid = validateInput(token);
                 setIsReCaptchaValid(tokenValid);
             }else{
                 setIsReCaptchaValid(false);
             }
 
         }
         
         // If our inputs are valid, submit the form
         if (firstNameValid && lastNameValid && orderNumberValid && postcodeValid && emailValid && confirmEmailValid && tokenValid) {
 
             // If we haven't successfully submitted an activation form, submit one. We do this to prevent spamming the submit button
             if ( formSuccess === false ) {
                 console.log("Form event submission");
 
                 saveToDatabase();
             }
         }
 
     }
 
     // Query the backend in so we can send a token to LIMS
     const saveToDatabase = async () => {
 
         // Format date into YYYY-MM-DD
         let formattedDate : string = dateOfBirth?.toISOString().slice(0, 10) ?? "";
 
         // Get recaptcha value
         let captchaToken;
         let token : string = "";
 
         if ( reCaptchaRef.current ) {
             captchaToken = reCaptchaRef.current.getValue();
 
             if (captchaToken !== null) {
                 token = captchaToken;
             }
         }
 
         // Submit our form to the backend of LIMS using an api request, if it's successful, we'll toggle a pop up to let the customer know that they were successful
         const fields = new FormData();
         orderNumberRef.current && fields.append("orderNumber", orderNumberRef.current.value);
         firstNameRef.current && fields.append("firstName", firstNameRef.current.value);
         lastNameRef.current && fields.append("lastName", lastNameRef.current.value);
         emailRef.current && fields.append("email", emailRef.current.value);
         activationCodeRef.current && fields.append("activationCode", activationCodeRef.current.value);
         postcodeRef.current && fields.append("postcode", postcodeRef.current.value);
         dateOfBirth && fields.append("dateOfBirth", formattedDate);
         reCaptchaRef.current && fields.append("recaptchaToken", token);
         genderRef.current && fields.append("gender", genderRef.current.value);
         fields.append("phoneNumber", phoneNumber ? phoneNumber : "");
 
         console.log("Order ref value");
         console.log(orderNumberRef.current && orderNumberRef.current.value);
 
         // Perform API request
         // Note: Do not pass a content type through to this request as the browser completes for you as well as your boundary
         const response = await fetch(endpoint, {
             method: "POST",
             body: fields
         });
 
         const data = await response.json();
 
         console.log(data);
         
         // Set form submission to true
         setFormSuccess(data.success);
 
         if (data.issue === "recaptcha") {
             setIsReCaptchaValid(false);
         }
 
         // Show popup after form submission whether it succeeds or fails
         setShowPopup(true);
 
         if (data.success === true){
            navigate(`/thank-you-hair/${orderNumberRef.current ? orderNumberRef.current.value : ""}/${firstNameRef.current ? firstNameRef.current.value : ""}-${lastNameRef.current ? lastNameRef.current.value : ""}`);
         }
 
     };
 
     return(
         <section className="hair-form-wrapper">
 
             <NavLink to="/amazon" className="hair-form-link"><BsArrowLeftSquareFill/>Go Back</NavLink>
 
             { showPopup && 
                 <p className={ `hair-form-popup ${ !formSuccess && 'hair-form-popup--error' }` }>
                     { formSuccess ? "Success! Refresh the page if you wish to submit another code" : "Please fix the errors found below" }
                 </p>
             }
 
             <form className="hair-form" onSubmit={submitHandler}>
 
                 <p className="hair-form__title">Activate Your Test Kit</p>
 
                 <div className={`hair-form__field ${ !isFirstNameValid && "hair-form__field--error" }`}>
                     <label id="first_name_label" htmlFor="first_name" className="hair-form__label">{ isFirstNameValid ? "First Name*" : "Error: First name is empty" }</label>
                     <input
                         type="text"
                         name="first_name"
                         aria-labelledby="first_name_label"
                         placeholder="Please enter your first name..."
                         aria-required
                         className={`hair-form__input ${ !isFirstNameValid && "hair-form__input--error" }`}
                         ref={firstNameRef}
                     />
                 </div>
 
                 <div className={`hair-form__field ${ !isLastNameValid && "hair-form__field--error" }`}>
                     <label id="last_name_label" htmlFor="last_name" className="hair-form__label">{ isLastNameValid ? "Last Name*" : "Error: Last name is empty" }</label>
                     <input
                         type="text"
                         name="last_name"
                         aria-labelledby="last_name_label"
                         placeholder="Please enter your last name..."
                         aria-required
                         className={`hair-form__input ${ !isLastNameValid && "hair-form__input--error" }`}
                         ref={lastNameRef}
                     />
                 </div>
 
                 <div className="hair-form__field">
                     <p className="hair-form__label">Date Of Birth*</p>
                     <DatePicker 
                         selected={dateOfBirth} 
                         onChange={(date) => setDateOfBirth(date)}
                         showYearDropdown={true}
                         dateFormat="dd/MM/yyyy"
                     />
                 </div>
 
                 <div className={`hair-form__field ${ !isPostcodeValid && "hair-form__field--error" }`}>
                     <label id="postcode_label" htmlFor="postcode" className="hair-form__label">{ isPostcodeValid ? "Postcode*" : "Error: Postcode isn't valid" }</label>
                     <input
                         type="text"
                         name="postcode"
                         aria-labelledby="postcode_label"
                         aria-required
                         placeholder="Please enter your postcode..."
                         className={`hair-form__input ${ !isPostcodeValid && "hair-form__input--error" }`}
                         ref={postcodeRef}
                     />
                 </div>
 
                 <div className={`hair-form__field ${ !isEmailValid && "hair-form__field--error" }`}>
                     <label id="email_address_label" htmlFor="email_address" className="hair-form__label">{ isEmailValid ? "Email Address*" : "Error: email address isn't valid" }</label>
                     <input
                         type="email"
                         name="email_address"
                         aria-labelledby="email_address_label"
                         aria-required
                         placeholder="Please enter your email address..."
                         className={`hair-form__input ${ !isEmailValid && "hair-form__input--error" }`}
                         ref={emailRef}
                     />
                 </div>
 
                 <div className={`hair-form__field ${ !isSecondEmailValid && "hair-form__field--error" }`}>
                     <label id="second_email_address_label" htmlFor="second_email_address" className="hair-form__label">{ isSecondEmailValid ? "Confirm Your Email Address*" : "Error: email addresses don't match" }</label>
                     <input
                         type="email"
                         name="second_email_address"
                         aria-labelledby="second_email_address_label"
                         aria-required
                         placeholder="Please confirm your email address..."
                         className={`hair-form__input ${ !isSecondEmailValid && "hair-form__input--error" }`}
                         ref={secondEmailRef}
                     />
                 </div>
 
                 <PhoneInput
                     country={'gb'}
                     value={phoneNumber?.toString()}
                     onChange={setPhoneNumber}
                 />
 
                 <div className={`hair-form__field ${ !isActivationCodeValid && "hair-form__field--error"}`}>
                     <label id="submission_code_label" htmlFor="submission_code" className="blood-form__label">{ isActivationCodeValid ? "Activation Code (Found in your test kit)*" : "Error: Activation Code must be exactly 10 characters long" }</label>
                     <input
                         type="text"
                         name="submission_code"
                         aria-labelledby="submission_code_label"
                         aria-required
                         placeholder="Please enter your Activation Code..."
                         className={`blood-form__input activation-code-input ${ !isActivationCodeValid && "blood-form__input--error" }`}
                         ref={activationCodeRef}
                         maxLength={15}
                     />
                 </div>
 
                <div className={`hair-form__field ${ (!isOrderNumberValid) && "hair-form__field--error" }`}>
                    <label id="order_number_label" htmlFor="order_number" className="hair-form__label">
                        { !isOrderNumberValid && orderNumberErrorMsg ? orderNumberErrorMsg :                      
                        "Amazon Order Number*" }
                    </label>
                        <input
                            type="text"
                            name="order_number"
                            aria-labelledby="order_number_label"
                            placeholder="Please enter your Order Number..."
                            aria-required
                            className={`hair-form__input`}
                            ref={orderNumberRef}
                            autoComplete="new-password"
                            title="Order number must contain only numbers."
                        />
                </div>
 
                 <div className="hair-form__field">
                     <label id="gender_label" htmlFor="gender" className="hair-form__label">Gender</label>
                     <select 
                         name="gender" 
                         aria-labelledby="gender_label" 
                         ref={genderRef} 
                         defaultValue="default" 
                         className="hair-form__dropdown"
                         style={{ 
                             background: "url('https://lims.healthystuff.com/arrow-down.svg')",
                             backgroundSize: "18px",
                             backgroundRepeat : "no-repeat",
                             backgroundPositionY : "50%",
                             backgroundPositionX : "calc(100% - 5px)"
                         }}
                     >
                         <option key="default" value={"default"} disabled>Choose Gender</option>
                         <option key="male" value={"Male"}>Male</option>
                         <option key="female" value={"Female"}>Female</option>
                         <option key="other" value={"Other"}>Other</option>
                         <option key="neither" value={"Prefer not to say"}>Prefer not to say</option>
                     </select>
                 </div>
 
                 {
                     formSubmitted && (isReCaptchaValid === false) &&
                     <p className="hair-form__recaptcha-error">Error: ReCaptcha is invalid</p>
                 }
                 <ReCAPTCHA
                     sitekey="6Ld_2pskAAAAAPFePhfrk2K7T3mVksszVs9XF16X"
                     ref={reCaptchaRef}
                 />
 
                 <button className="hair-form__submit">Submit</button>
 
             </form>
 
         </section>
     );
 };
 
 export default HairForm;
 
